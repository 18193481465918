import { useParams } from '@solidjs/router'
import SubscriptionGate from '~/components/subscription-gate/subscription-gate'
import TypeProofreadingView from '~/components/type-proofreading-view/type-proofreading-view'
import { TypeProofreadingParams } from '~/types/routes/series/type-proofreading'

export default function TypeProofreading() {
  const { id } = useParams<TypeProofreadingParams>()
  return (
    <SubscriptionGate requiredRole='typeProofreading'>
      <TypeProofreadingView chapterId={id} />
    </SubscriptionGate>
  )
}
